[data-layout="section"] {
	width: 100%;
	max-width: var(--section-inset-width);
	margin-inline: auto;
}

[data-layout="wide"] {
	width: 100%;
	max-width: var(--page-inset-width);
	margin-inline: auto;

	@media (--lg) {
		max-width: min(var(--page-inset-width), calc(var(--section-inset-width) + 150px * 2));
	}
}

[data-layout="content"] {
	width: 100%;
	max-width: var(--content-width);
	margin-inline: auto;
}

[data-layout="full"] {
	width: 100%;
	max-width: 100%;
}

[data-layout="page"] {
	width: 100%;
}

[data-layout] {
	& + :where([data-layout]) {
		margin-top: var(--blok-margin);
	}
}
