:root {
	--primary-dark-blue: #0c0f27;
	--primary-dark-blue-08: #0c0f27cc;
	--primary-dark-blue-06: #0c0f2799;
	--primary-dark-blue-04: #0c0f2766;
	--primary-dark-blue-02: #0c0f2733;
	--primary-dark-blue-01: #0c0f271a;
	--primary-dark-blue-005: #0c0f270d;
	--primary-greige: #f5f3f0;
	--primary-grey: #e5e5e5;
	--primary-white: #fff;
	--secondary-blue: #031d41;
	--secondary-green: #1f3010;
	--secondary-beige: #edeae7;
	--accent-red: #941826;
	--accent-red-005: #9418260d;
	--accent-green: #068841;
	--accent-green-005: #0688410d;
	--accent-yellow: #eae10b;
	--accent-yellow-005: #eae10b0d;
	--accent-orange: #941826;
	--accent-orange-005: #ec82210d;
	--color-background: var(--primary-greige);
	--color-foreground: var(--primary-dark-blue);
	--color-foreground-inverted: var(--primary-white);
	--color-border: var(--primary-dark-blue-01);
	--color-border-focus: var(--primary-dark-blue);
	--text-primary: var(--color-foreground);
	--text-secondary: color-mix(in srgb, var(--primary-dark-blue) 50%, white);
	--product-card-background: #d2d2d2;
}
