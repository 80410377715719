/* stylelint-disable no-duplicate-selectors */
:root {
	--scale-trigger-size: 1792; /* Controls the max width of the page */
	--scale-max-size: 1792; /* Controls the max width of the page */
	--page-width: 9999px /* Controls the max width of the page */;
	--section-width: 1490px;
	--blok-margin: 30px; /* Controls the spacing between bloks */
	--section-margin: 30px;
	--page-inset: 15px;
	--page-inset-width: calc(min(calc(100vw - var(--page-inset) * 2), var(--page-width)));
	--section-inset-width: calc(
		min(calc(100vw - var(--page-inset) * 2), var(--section-width))
	);
	--content-width: min(var(--page-inset-width), 1190px);
	--text-width: min(var(--page-inset-width), 737px);
	--border-rounded: 99px;
	--border-radius-sm: 4px;
	--border-radius-md: 8px;
	--header-min-height: 56px;
	--snackbar-height: 32px;
	--header-height: calc(var(--header-min-height) + var(--snackbar-height));
	--button-font-weight: 500;
	--gap: var(--space-sm);
	--snackbar-visibility: 0px;
	--filterbar-top-position: calc(var(--header-min-height) + var(--space-xs));
	--modal-offset: 15px;

	@media (--md) {
		--page-inset: 30px;
		--header-min-height: 80px;
	}

	@media (--2xl) {
		--page-inset: 60px;
	}
}

:root {
	--display-line-height: 110%;
	--display-1-size: 50px;
	--display-2-size: 40px;
	--display-3-size: 30px;
	--heading-line-height: 130%;
	--heading-1-size: 40px;
	--heading-2-size: 25px;
	--heading-3-size: 20px;
	--heading-4-size: 17px;
	--heading-5-size: 11px;
	--body-line-height: 160%;
	--body-1-size: 15px;
	--body-2-size: 13px;
	--body-3-size: 11px;
	--utility-1-size: 19px;
	--utility-2-size: 17px;
	--utility-3-size: 15px;
	--utility-4-size: 13px;
	--utility-5-size: 11px;
	--utility-6-size: 9px;
	--utility-line-height: 110%;

	@media (--md) {
		/*
    --heading-line-height: 120%;
    --heading-1-size: fluidSize(40px, 68px);
    --heading-2-size: fluidSize(25px, 40px);
    --heading-3-size: fluidSize(20px, 30px);
    --heading-4-size: fluidSize(17px, 20px);
    --heading-5-size: fluidSize(11px, 15px);
    --body-1-size: fluidSize(15px, 19px);
    --body-2-size: fluidSize(13px, 15px);
    --body-3-size: fluidSize(11px, 15px);
    */
		--blok-margin: 120px;
		--section-margin: 60px;
		--display-1-size: fluidSize(50px, 130px);
		--display-2-size: fluidSize(40px, 110px);
		--display-3-size: fluidSize(30px, 90px);
		--heading-line-height: 120%;
		--heading-1-size: 68px;
		--heading-2-size: 40px;
		--heading-3-size: 30px;
		--heading-4-size: 20px;
		--heading-5-size: 15px;
		--body-1-size: 19px;
		--body-2-size: 15px;
		--body-3-size: 15px;
	}
}

:root {
	--space-2xs: 5px;
	--space-xs: 10px;
	--space-sm: 15px;
	--space-md: 20px;
	--space-lg: 30px;
	--space-xl: 60px;
	--space-2xl: 120px;
}
