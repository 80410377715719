@import url("@frend-digital/theme/css/defaults.css");
@import url("./colors.css");
@import url("./scaling.css");
@import url("./layout.css");
@import url("./animation.css");

html {
	scroll-behavior: smooth;
	color-scheme: light;
	scrollbar-width: thin;
}

:where(*) {
	text-underline-offset: 2px;
}

:where(button) {
	border: none;
	padding: 0;
	background: none;
	cursor: pointer;
	appearance: none;
	color: inherit;
}

:where(body) {
	color: var(--color-foreground);
	background-color: var(--color-background);
	font-family: var(--font-beatrice);
	transition: 0.3s ease;
	transition-property: background-color, color;
	line-height: var(--body-line-height);
}

:where(p) {
	margin: 0;
}

body {
	position: relative;

	&:has(dialog[open]) {
		/* Scrollbar is hidden so we must offset to avoid layout shift */

		/* Offset the scrollbar width */

		/* Offset the scrollbar height */

		dialog {
			z-index: 2;
		}

		overflow: hidden;

		/* &::after {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: rgb(0 0 0 / 20%);
			z-index: 1;
		} */
	}
}

.mobile {
	@media (--md) {
		display: none;
	}
}

.desktop {
	@media (--max-md) {
		display: none;
	}
}

details summary::-webkit-details-marker,
summary::marker {
	display: none;
	list-style-type: none;
}

[data-viewport="mobile"] {
	display: block;

	@media (--md) {
		display: none;
	}
}

[data-viewport="desktop"] {
	display: none;

	@media (--md) {
		display: block;
	}
}

:where(fieldset) {
	min-width: 0;
	margin: 0;
	padding: 0;
	border: 0;
}

:where(img) {
	object-fit: cover;
}

body.scroll-locked::after,
body:has(.mega-menu[data-state="open"])::after {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	content: "";
	background: rgb(0 0 0 / 25%);
	z-index: 1;
	animation: fade-in 250ms both;
}

.scroll-locked {
	overflow: hidden;

	@media (--md) {
		overflow: unset;
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

body:has(.full-bleed) {
	& .breadcrumbs {
		/* By request of scandinavian */
		position: absolute;
		top: var(--header-height);
		left: var(--page-inset);
		z-index: var(--z-above);
		color: var(--primary-white);
		display: none !important;
	}
}

.mapboxgl-popup-content.mapboxgl-popup-content {
	padding: 0;
}

.mapboxgl-popup-close-button.mapboxgl-popup-close-button {
	display: none;
}

.mapboxgl-popup {
	max-width: 100% !important;
}

.mapboxgl-canvas {
	width: 100% !important;
}
